/*
** 只在生产模式的客户端中使用
*/

export default ({ app: { router }, store }) => {
    /*
    ** 每次路由变更时进行pv统计
    */
    router.afterEach((to, from) => {
        /*
        ** 告诉 GA 增加一个 PV
        */
        if (process.client && process.env.NODE_ENV === 'production') {
           try{
            gtag('event', 'page_view', { 'send_to': 'G-H684FDKJ2X',to:to.fullPath,from:from.path }); //stg環境key
           } catch(err){
             console.log(err);
           }
        }

    })
}
