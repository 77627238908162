import Vue from 'vue'
import NoData from '../components/common/NoData.vue'

import errTip from '../components/common/errTip/index.vue'

import HMM from "~/web-base/components/HandmadeMessage/index.js";
import HMSlider from "../components/HandmadeSlider/index.js";

Vue.use(HMSlider);

import VueQriously from 'vue-qriously';
import VueClipboard from 'vue-clipboard2';

import { hideGlobalLoading } from '~/utils/utils'
import VAnimateCss from 'v-animate-css';

//注册 基础组件

import '~/component_basic/componentRegister.js'

Vue.component('v-errtip',errTip);
Vue.component('no-data', NoData); // 全局暂无数据组件

Vue.use(VAnimateCss);
Vue.use(VueQriously);
Vue.use(VueClipboard);
window.BT = {
  util: {
    info(msg, title) {
      HMM(msg);
    },
    success(msg, title) {
      var title = title || '提示';
      HMM({ type: 'success', message: msg });
    },
    error(msg, title) {
      var title = title || '提示';
      HMM({ type: 'error', message: msg });
    },
    warning(msg, title) {
      var title = title || '警告';
      HMM({ type: 'warning', message: msg });
    },
    // 打开新页面时，需要处理一下
    openTab(url) {
      if (url) {
        let myWindow = window.open();
        myWindow.opener = null;
        myWindow.location = url;
        myWindow.target = '_blank';
      } else {
        console.error(`When a new tab page is opened, the URL does not exist`);
      }
    },
    getContractRedictPath(path) {
      if (path.indexOf('/game/') > -1) {
        return 'game/'
      }
      if (path.indexOf('/delivery/') > -1) {
        return 'delivery/'
      }
      return 'swap/'
    },
    getNewContractRedictPath(path) {
      if (path.indexOf('/game/') > -1) {
        return 'game/'
      }
      if (path.indexOf('/delivery/') > -1) {
        return 'delivery/'
      }
      return 'swapnew/'
    },
    trim: function (str) {
      return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    }
  },

  // 是否是移动设备
  isMobileDevices: () => {
    var userAgentInfo = navigator.userAgent.toLowerCase();
    var agents = ",android,iphone,symbianos,windows phone,ipad,ipod";
    if (userAgentInfo.indexOf(',' + agents) > -1) {
      return true;
    } else {
      window.onresize = function () {
        var normalWidth = window.innerWidth;
        if (normalWidth <= 750) {
          return true;
        }
      }
    }
    return false;
  }
};
window.hideGlobalLoading = hideGlobalLoading;
