import Vue from 'vue';

import { broadcastChannel } from '~/utils/broadcastChannel';
Vue.mixin({
  methods: {
    // syncIdentityWhenMissing() {
    //   const rToken = getCookie('bt_rtoken') || localStorage.getItem('bt_rToken') || getCookie('bt_rtoken_client');
    //   const uid = sessionStorage.getItem('bt_uid') || localStorage.getItem('bt_uid');
    //   const accessToken = sessionStorage.getItem('bt_ccToken') || localStorage.getItem('bt_ccToken');
    //   if (rToken){
    //     if(rToken?.length > 0) {
    //       setCookie('bt_rtoken_client', rToken, {});
    //       broadcastChannel('setItem', 'bt_rToken', rToken);
    //     } else {
    //       broadcastChannel('removeItem', 'bt_rToken');
    //     }
    //   }
    //   if (uid) {
    //     if(uid?.length > 0) {
    //       localStorage.setItem('bt_uid', uid);
    //       broadcastChannel('setItem', 'bt_uid', uid);
    //     } else {
    //       broadcastChannel('removeItem','bt_uid');;
    //       broadcastChannel('removeItem', 'bt_uid');
    //     }
    //   }
    //   if (accessToken) {
    //     if(accessToken?.length > 0) {
    //       console.log('0000 accessToken-session', sessionStorage.getItem('bt_ccToken'))
    //       console.log('0000 accessToken-local', localStorage.getItem('bt_ccToken'))
    //       localStorage.setItem('bt_ccToken', accessToken);
    //       broadcastChannel('setItem', 'bt_ccToken', accessToken);
    //     } else {
    //       broadcastChannel('removeItem','bt_ccToken');;
    //       broadcastChannel('removeItem', 'bt_ccToken');
    //     }
    //   }
    // },
    downloadJumpDl(type) {
      let locale = this.$i18n.locale;
      let href = '';
      let Host = location.host;
      let locationHref = location.href;
      //locationHref = "https://www.weikestg.info/en/"
      //Host = 'weikestg.info'
      //locationHref = "https://stg-www.weex.tech/zh-CN/"
      //Host = 'stg-www.weex.tech'
      if (
        locationHref.indexOf('weexdev') != -1 ||
        locationHref.indexOf('test') != -1 ||
        locationHref.indexOf('localhost') != -1
      ) {
        //Dev,Test
        href = 'https://test-support.weex.tech/' + locale + '/jumpdl?' + type;
      } else if (locationHref.indexOf('stg-www') != -1) {
        href = 'https://stg-support.weex.tech/' + locale + '/jumpdl?' + type;
      } else {
        //PRD
        let domainArr = Host.split('.');
        let domainStr = domainArr[domainArr.length - 2].concat('.').concat(domainArr[domainArr.length - 1]);
        href = 'https://support.' + domainStr + '/' + locale + '/jumpdl?' + type;
      }
      //debugger
      window.open(href);
    },
    go2AppStore() {
      let href =
        'https://apps.apple.com/us/app/weex%E5%94%AF%E5%AE%A2-%E6%95%B0%E5%AD%97%E8%B4%A7%E5%B8%81%E5%90%88%E7%BA%A6%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0-btc-eth/id1609350789';
      window.open(href);
    },
    go2GooglePlay() {
      let href = 'https://play.google.com/store/apps/details?id=com.wake.weexprd';
      window.open(href);
    }
  },
  mounted() {
    // 启动token鉴权
    // this.$startAuthToken();
  }
});
