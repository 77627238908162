import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enElementLocale from 'element-ui/lib/locale/lang/en';
import zhElementLocale from 'element-ui/lib/locale/lang/zh-CN';
import twElementLocale from 'element-ui/lib/locale/lang/zh-TW';
import koElementLocale from 'element-ui/lib/locale/lang/ko';
import viElementLocale from 'element-ui/lib/locale/lang/vi';
// import zhLocale from '~/locales/zh-cn.json';
// import enLocale from '~/locales/en.json';
// import koLocale from '~/locales/ko-kr.json';
// import twLocale from '~/locales/zh-tw.json';
import ElementLocale from 'element-ui/lib/locale';

Vue.use(VueI18n);

export default ({ app, store }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'en', // 默認語系
    // messages: {
      // 'zh-CN': {  ...zhLocale },
      // 'en': {  ...enLocale, enElementLocale },
      // 'ko': {  ...koLocale },
      // 'zh-TW': {  ...twLocale },
      // en: { ...require('~/locales/en.json'), ...enLocale },
      // ko: { ...require('~/locales/ko-kr.json'), ...koLocale },
      // ja: { ...require('~/locales/ja.json'), ...jaLocale },
      // vi: { ...require('~/locales/vi.json'), ...viLocale },
      // 'zh-TW': { ...require('~/locales/zh-tw.json'), ...twLocale },
      // ru: { ...require('~/locales/ru.json'), ...ruLocale },
      // es: { ...require('~/locales/es.json'), ...esLocale }
    // }
  });
  switch (store.state.locale) {
    case 'en':
      app.i18n.setLocaleMessage('en',  { ...require('~/locales/en.json'), ...enElementLocale })
      break;
    case 'zh-CN':
      app.i18n.setLocaleMessage('zh-CN',  { ...require('~/locales/zh-cn.json'), ...zhElementLocale })
      break;
    case 'zh-TW':
      app.i18n.setLocaleMessage('zh-TW',  { ...require('~/locales/zh-tw.json'), ...twElementLocale })
      break;
    case 'ko':
      app.i18n.setLocaleMessage('ko',  { ...require('~/locales/ko-kr.json'), ...koElementLocale })
      break;
    case 'vi':
      app.i18n.setLocaleMessage('vi',  { ...require('~/locales/vi.json'), ...viElementLocale })
      break;
  }

  ElementLocale.i18n((key, value) => app.i18n.t(key, value));
  app.i18n.path = link => {
    // if (app.i18n.locale === app.i18n.fallbackLocale) {
    //   return `/${link}`
    // }
    if(app.i18n.locale === 'en'){
      return `/${link}`;
    }else {
      let curLink = `/${app.i18n.locale}/${link}`
      // 去除路径末尾的斜杠
      if (curLink.endsWith('/')) { 
        curLink = curLink.slice(0, -1);
      }
      return curLink;
    }
  };
};
