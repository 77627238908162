import Vue from 'vue';
import { WebsocketProvider } from '../utils/WebsocketProvider';
import RealtimePublic  from '../src/newWebsocket2/service/ws.public';
import WebsocketHero from '../src/WebsocketHero2.0';

Vue.use(WebsocketProvider);
Vue.use({
  install: function (Vue) {
    Vue.prototype.$hero = WebsocketHero;
    // 共享保证金ws实例
    Vue.prototype.$publicWS = RealtimePublic;
  }
});
