
import Vue from 'vue'
import clickoutside from "./clickoutside";
import VueMarqueeSlider from 'vue-marquee-slider';

import {
  Autocomplete,
  Button,
  Carousel,
  CarouselItem,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Loading,
  MessageBox,
  Option,
  Pagination,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tooltip,
  Upload,
} from "element-ui";

Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Cascader);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Row);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(VueMarqueeSlider);

Vue.use(Loading.directive);

Vue.directive('clickoutside', clickoutside);
const msgbox = MessageBox;
const {alert,confirm,prompt} = msgbox
Vue.prototype.$msgbox = msgbox;
Vue.prototype.$alert = alert;
Vue.prototype.$confirm = confirm;
Vue.prototype.$prompt = prompt;










