import Vue from 'vue';
import { ErrorLog } from '~/utils/sensorsEvent';
import { isClient } from '~/utils/envHelper';
import { isProduction } from '~/utils/envHelper';

// 捕获应用异常，用于捕获非Vue实例内的错误
if (isClient) {
  window.onerror = function(err) {
    // 若是在开发阶段，打印错误信息
    if (!isProduction) {
      console.error(err);
    }
    // 神策上报错误信息
    ErrorLog(err);
  };
}

// 捕获Vue实例内的错误，如组件内报错
Vue.config.errorHandler = function(err) {
  // 若是在开发阶段，打印错误信息
  if (!isProduction) {
    console.error(err);
  }
  // 神策上报错误信息
  ErrorLog(err.message);
};
