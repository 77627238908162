import { initializeApp, getApps } from "firebase/app"

const firebaseConfig = {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDERID,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENTID
}
// //Test 環境
// const firebaseConfig = {
//     apiKey: "AIzaSyD-PI7wKlPPWqRs898TqXXW3Gid37CshQE",
//     authDomain: "weex-app-81f42.firebaseapp.com",
//     projectId: "weex-app-81f42",
//     storageBucket: "weex-app-81f42.appspot.com",
//     messagingSenderId: "699957691259",
//     appId: "1:699957691259:web:282948e57f8f10bf8f33de",
//     measurementId: "G-7SFPNQ0LHL"
// };

// //Stg 環境
// const firebaseConfig = {
//   apiKey: "AIzaSyD8KSkbzEN7vdIHlj8Fl7TNQtBDyvuq6lE",
//   authDomain: "weex-stg.firebaseapp.com",
//   projectId: "weex-stg",
//   storageBucket: "weex-stg.appspot.com",
//   messagingSenderId: "35215263386",
//   appId: "1:35215263386:web:aae078b8568d9c5c5b4123",
//   measurementId: "G-H684FDKJ2X"
// };

//Prd 環境
// const firebaseConfig = {
//   apiKey: "AIzaSyAlDwrdAAud0Sf4tM-QuBxCIR3iAOV8axY",
//   authDomain: "weex-83521.firebaseapp.com",
//   projectId: "weex-83521",
//   storageBucket: "weex-83521.appspot.com",
//   messagingSenderId: "322040710743",
//   appId: "1:322040710743:web:097325fe1f932797168512",
//   measurementId: "G-VJ1TZQ4HKS"
// };

// const firebaseConfig = { //dan 環境
//     apiKey: "AIzaSyABUgogbDR8ytRrVWbZYrw6azQ2svoqGRw",
//     authDomain: "weex-92f4b.firebaseapp.com",
//     projectId: "weex-92f4b",
//     storageBucket: "weex-92f4b.appspot.com",
//     messagingSenderId: "253093360179",
//     appId: "1:253093360179:web:f30f51391eb83eddc9e946",
//     measurementId: "G-HQX2HBYS6S"
// };
const apps = getApps()
let firebaseApp = {};
if (!apps.length) {
    firebaseApp = initializeApp(firebaseConfig)
}
else{
    firebaseApp = apps[0]
}
// const analytics = getAnalytics(firebaseApp);
export {firebaseApp}
