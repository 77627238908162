import Vue from 'vue';
import CryptoJS from "crypto-js";
Vue.mixin({
    methods: {
        /**
         * AES-256-CBC对称解密
         * @param textBase64 {string} 要解密的密文，Base64格式
         * @param secretKey {string} 密钥，43位随机大小写与数字
         * @returns {string} 解密后的明文
         */
        AES_CBC_DECRYPT(textBase64, secretKey, iv) {
            //debugger
            var keyHex = CryptoJS.enc.Utf8.parse(secretKey);
            var ivHex = CryptoJS.enc.Utf8.parse(iv);
            var decrypt = CryptoJS.AES.decrypt(textBase64, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            });
            var decryptedStr = CryptoJS.enc.Utf8.stringify(decrypt);
            return decryptedStr;
        },
    }
})