import Vue from 'vue';
import { getInstance } from '~/utils/doRequest';
import { isClient } from '~/utils/envHelper';
import TokenCookie, {TokenKey} from "@/utils/TokenCookie";

const authInstance = getInstance('');

/**
 * token鉴权
 * 1. refreshToken/bt_ccToken/bt_rToken 缺失任一或多个
 * 1.1 清空缓存中的 refreshToken/bt_ccToken/bt_rToken（用户未登录）
 * 2. 有refreshToken/bt_ccToken/bt_rToken
 * 2.1 refreshToken过期
 * 2.1.1 清空缓存中的 refreshToken/bt_ccToken/bt_rToken
 * 2.2 refreshToken未过期
 * 2.2.1 bt_ccToken过期
 * 2.2.1.1 清空缓存中的 refreshToken/bt_ccToken/bt_rToken
 * 2.2.2 bt_ccToken未过期
 * 2.2.2.1 距过期6小时内
 * 2.2.2.1.1 请求接口 /v1/user/refresh_token更新refreshToken/bt_ccToken
 * 2.2.2.2 距过期6小时外 - 不处理
 */
function setAuthTokenAction() {
  let refreshToken, ccToken, rToken, ccTokenExpire, refreshTokenExpire;
  if (isClient) {
    refreshToken = TokenCookie.get(TokenKey.REFRESH_TOKEN);
    ccToken = TokenCookie.get(TokenKey.CC_TOKEN);
    rToken = TokenCookie.get(TokenKey.R_TOKEN);
    ccTokenExpire = TokenCookie.get(TokenKey.CC_TOKEN_EXPIRE);
    refreshTokenExpire = TokenCookie.get(TokenKey.REFRESH_TOKEN_EXPIRE);
  }
  if (!refreshToken || !ccToken || !rToken) {
    // refreshToken/bt_ccToken/bt_rToken 缺失任一或多个
    Vue.prototype.$logout(); //清空缓存中的 refreshToken/bt_ccToken/bt_rToken
  } else {
    // 有refreshToken/bt_ccToken/bt_rToken
    let nowTime = new Date().getTime();
    if (Number(nowTime) > Number(refreshTokenExpire || 0)) {
      //refreshToken过期
      Vue.prototype.$logout(); //清空缓存中的 refreshToken/bt_ccToken/bt_rToken
    } else {
      //refreshToken未过期
      if (Number(nowTime) > Number(ccTokenExpire || 0)) {
        //bt_ccToken过期
        Vue.prototype.$logout(); //清空缓存中的 refreshToken/bt_ccToken/bt_rToken
      } else {
        //bt_ccToken未过期
        if (ccTokenExpire && Number(ccTokenExpire) - Number(nowTime) < 6 * 60 * 60 * 1000) {
          //距过期6小时内
          try {
            authInstance.post('/v1/user/login/refresh_token', { headers: { refreshToken } }).then(res => {
              if (res.code === '00000' && res.data) {
                if (isClient) {
                  // 加refreshToken，放入token过期时间
                  if (res.data.accessToken) TokenCookie.set(TokenKey.CC_TOKEN, res.data.accessToken);
                  if (res.data.accessTokenExpire) TokenCookie.set(TokenKey.CC_TOKEN_EXPIRE, res.data.accessTokenExpire);
                  if (res.data.refreshToken) TokenCookie.set(TokenKey.REFRESH_TOKEN, res.data.refreshToken);
                  if (res.data.refreshTokenExpire)TokenCookie.set(TokenKey.REFRESH_TOKEN_EXPIRE, res.data.refreshTokenExpire);
                }
              } else {
                Vue.prototype.$logout(); //清空缓存中的 refreshToken/bt_ccToken/bt_rToken
              }
            });
          } catch (err) {
            Vue.prototype.$logout(); //清空缓存中的 refreshToken/bt_ccToken/bt_rToken
          }
        }
      }
    }
  }
}

// 启动token鉴权
Vue.prototype.$startAuthToken = _ => {
  // 若存在定时器，先清空
  let authTokenInterval = Vue.prototype.$authTokenInterval;
  if (authTokenInterval) clearInterval(authTokenInterval);
  // 设置定时器 - 5分钟校验一次
  setTimeout(_ => setAuthTokenAction()); // 启动时先执行一遍
  authTokenInterval = setInterval(_ => {
    setAuthTokenAction();
  }, 5 * 60 * 1000);
  Vue.prototype.$authTokenInterval = authTokenInterval;
};

// 停止token鉴权
Vue.prototype.$endAuthToken = _ => {
  let authTokenInterval = Vue.prototype.$authTokenInterval;
  if (authTokenInterval) clearInterval(authTokenInterval);
};
